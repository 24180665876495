import * as types from "../types/user_type";

const initialState = {
  user: {},
  workExperiences: [],
  education: [],
  educations: [],
  skills: [],
  skillsOption: [],
  language: [],
  languageOption: [],
  jobTitleOption: [],
  educationalInstitution: [],
  showLeaveModal: false,
  showUserProfileModal: false,
  userProfileDialog: {
    edit: false,
    section: "",
    index: 0,
  },
  isEdited: false,
  stateRegions: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS: {
      return {
        ...state,
        user: { ...action.payload.user },
      };
    }
    case types.SIGNUP_SUCCESS: {
      return {
        ...state,
        user: { ...action.payload.user },
      };
    }
    case types.LOGOUT: {
      return {
        ...state,
        user: {},
      };
    }
    case types.UPLOAD_RESUME_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          resume: action?.payload?.user?.resume,
          approved: action?.payload?.user?.approved,
          state: action?.payload?.user?.state,
        },
      };
    }
    case types.GET_USER_INFO_SUCCESS: {
      return {
        ...state,
        user: { ...action?.payload?.user },
      };
    }
    case types.SAVE_EDUCATION_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          // educations: action?.payload,
        },
      };
    }
    case types.SAVE_WORK_EXPERIENCE_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          // workingExperiences: action?.payload?.latestWorkExperience,
        },
      };
    }
    case types.SAVE_BASIC_INFO_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          name: action?.payload?.latestUser?.name,
          gender: action?.payload?.latestUser?.gender,
          birthDate: action?.payload?.latestUser?.birthDate,
          nationality: action?.payload?.latestUser?.nationality,
          mobileNumber: action?.payload?.latestUser?.mobileNumber,
          currentCompany: action?.payload?.latestUser?.currentCompany,
          shortSummary: action?.payload?.latestUser?.shortSummary,
          onboarding: action?.payload?.latestUser?.onboarding,
          major: action?.payload?.latestUser?.major,
          profile: action?.payload?.latestUser?.profile,
        },
      };
    }
    case types.SAVE_PROFILE_IMAGE_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          profileImageSquare: action?.payload?.latestUser?.profileImageSquare,
        },
      };
    }
    case types.FETCH_PDPA_STATUS_SUCCESS: {
      return {
        ...state,
        pdpaAccepted: action.payload,
      };
    }
    case types.UPDATE_PDPA_STATUS_SUCCEED: {
      return {
        ...state,
        pdpaAccepted: action.payload,
      };
    }
    case types.UPDATE_PDPA_STATUS_FAILED: {
      return {
        ...state,
      };
    }
    case types.UPDATE_ONBOARDING_SUCCESS: {
      return {
        ...state,
        onboarding: action.payload,
      };
    }
    case types.UPDATE_ONBOARDING_FAILED: {
      return {
        ...state,
      };
    }
    case types.ADD_WORK_EXPERIENCE: {
      return {
        ...state,
        workExperiences: [...state.workExperiences, action.payload],
      };
    }

    case types.REMOVE_WORK_EXPERIENCE: {
      return {
        ...state,
        workExperiences: state.workExperiences.filter(
          (_, index) => index !== action.payload
        ),
      };
    }

    case types.UPDATE_WORK_EXPERIENCE: {
      return {
        ...state,
        workExperiences: action.payload,
      };
    }

    case types.ADD_EDUCATION: {
      return {
        ...state,
        education: [...state.education, action.payload],
      };
    }

    case types.REMOVE_EDUCATION: {
      return {
        ...state,
        education: state.education.filter(
          (_, index) => index !== action.payload
        ),
      };
    }

    case types.UPDATE_EDUCATION: {
      return {
        ...state,
        education: action.payload,
      };
    }

    case types.UPDATE_SKILLS: {
      return {
        ...state,
        skills: action.payload,
      };
    }

    case types.UPDATE_LANGUAGE: {
      return {
        ...state,
        language: action.payload,
      };
    }

    case types.UPDATE_LEAVE_MODAL: {
      return {
        ...state,
        showLeaveModal: action.payload,
      };
    }

    case types.UPDATE_USER_PROFILE_MODAL: {
      return {
        ...state,
        showUserProfileModal: action.payload,
      };
    }

    case types.UPDATE_USER_PROFILE_DIALOG: {
      return {
        ...state,
        userProfileDialog: { ...action.payload },
      };
    }

    case types.UPDATE_IS_EDITED: {
      return {
        ...state,
        isEdited: action.payload,
      };
    }

    case types.GET_STATEREGION_SUCCEEDED: {
      return {
        ...state,
        stateRegions: action.payload,
      };
    }

    case types.GET_LANGUAGE_SUCCEED: {
      return {
        ...state,
        languageOption: { ...action?.payload },
      };
    }

    case types.SAVE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        language: action?.payload,
      };
    }

    case types.GET_SKILLS_SUCCEED: {
      return {
        ...state,
        skillsOption: { ...action?.payload },
      };
    }

    case types.SAVE_SKILLS_SUCCESS: {
      return {
        ...state,
        skills: action?.payload,
      };
    }

    case types.GET_JOB_TITLES_SUCCEED: {
      return {
        ...state,
        jobTitleOption: { ...action?.payload },
      };
    }

    case types.GET_EDUCATION_INSTITUTION_SUCCEED: {
      return {
        ...state,
        educationalInstitution: { ...action?.payload },
      };
    }

    default:
      return state;
  }
};
