import { useDispatch } from "react-redux";
import { hideSnackBar, showSnackBar } from "../redux/actions/snackbar_action";
import { store } from "../redux/stores/store";

/**
 *
 * @param {String} message
 * @param {String} severity
 * @param {String} fallbackMessage
 * @returns
 */
export default function useTriggerSnackbar() {
  const dispatch = useDispatch();

  function triggerSnackbarFunc(funcProp) {
    const { message: _message, severity } = funcProp;

    const snackbarParams = {
      message: _message,
      severity: severity,
    };

    if (store.getState().snackbar.showSnackBar) {
      dispatch(hideSnackBar()).then(() => {
        setTimeout(() => {
          dispatch(showSnackBar(snackbarParams));
        }, 500);
      });
    } else {
      dispatch(showSnackBar(snackbarParams)).then(() => {
        setTimeout(() => {
          dispatch(hideSnackBar());
        }, 2000);
      });
    }
  }

  return [triggerSnackbarFunc];
}
