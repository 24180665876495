const sendTrackingEvent = (params) => {
  try {
    dataLayer.push(params);
  } catch (error) {
    // tracking error
  }
};

const sendClevertapProfileEvent = (params) => {
  try {
    clevertap.profile.push({
      Site: params,
    });
  } catch (error) {
    console.error("Send clevertap profile event error", error.toString());
  }
};

export { sendTrackingEvent, sendClevertapProfileEvent };
