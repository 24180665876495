//FETCHING COMPANY LIST
export const FETCHING_COMPANY_KEY = "FETCHING_COMPANY_KEY";
export const FETCHING_COMPANIES = "FETCHING_COMPANIES";
export const FETCH_COMPANIES_SUCCEED = "FETCH_COMPANIES_SUCCEED";
export const FETCH_COMPANIES_FAILED = "FETCH_COMPANIES_FAILED";
export const END_OF_COMPANY_LIST = "END_OF_COMPANY_LIST";
export const CLEAR_COMPANY_LIST = "CLEAR_COMPANY_LIST";
export const NO_COMPANIES_FOUND = "NO_COMPANIES_FOUND";
export const CLEAR_END_CURSOR = "CLEAR_END_CURSOR";

// FOR COMPANY SUGGESTION
export const FETCH_COMPANY_SUGGESTION_KEY = "FETCH_COMPANY_SUGGESTION_KEY";
export const FETCH_COMPANY_SUGGESTION = "FETCH_COMPANY_SUGGESTION";
export const FETCH_COMPANY_SUGGESTION_SUCCEED =
  "FETCH_COMPANY_SUGGESTION_SUCCEED";
export const FETCH_COMPANY_SUGGESTION_FAILED =
  "FETCH_COMPANY_SUGGESTION_FAILED";
export const COMPANY_SUGGESTION_END = "COMPANY_SUGGESTION_END";
export const CLEAR_COMPANY_SUGGESTION = "CLEAR_COMPANY_SUGGESTION";

//COMPANY FILTER
export const UPDATE_COMPANY_FILTER = "UPDATE_COMPANY_FILTER";

//FETCHING DISCOVER MORE COMPANY LIST
export const FETCHING_DISCOVER_MORE_COMPANIES =
  "FETCHING_DISCOVER_MORE_COMPANIES";
export const FETCH_DISCOVER_MORE_COMPANIES_SUCCEED =
  "FETCH_DISCOVER_MORE_COMPANIES_SUCCEED";
export const FETCH_DISCOVER_MORE_COMPANIES_FAILED =
  "FETCH_DISCOVER_MORE_COMPANIES_FAILED";
export const CLEAR_DISCOVER_MORE_COMPANY_LIST =
  "CLEAR_DISCOVER_MORE_COMPANY_LIST";
export const END_OF_DISCOVER_MORE_COMPANY_LIST =
  "END_OF_DISCOVER_MORE_COMPANY_LIST";
export const NO_DISCOVER_MORE_COMPANIES_FOUND =
  "NO_DISCOVER_MORE_COMPANIES_FOUND";

//FETCHING CP DISCOVER MORE COMPANY
export const FETCHING_CP_DISCOVER_MORE_COMPANIES =
  "FETCHING_CP_DISCOVER_MORE_COMPANIES";
export const FETCH_CP_DISCOVER_MORE_COMPANIES_SUCCEED =
  "FETCH_CP_DISCOVER_MORE_COMPANIES_SUCCEED";
export const FETCH_CP_DISCOVER_MORE_COMPANIES_FAILED =
  "FETCH_CP_DISCOVER_MORE_COMPANIES_FAILED";

//COMPANY PROFILE PAGE
export const UPDATE_SELECTED_COMPANY = "UPDATE_SELECTED_COMPANY";
export const FETCHING_COMPANY = "FETCHING_COMPANY";
export const FETCHING_COMPANY_SUCCEED = "FETCHING_COMPANY_SUCCEED";
export const FETCHING_COMPANY_FAILED = "FETCHING_COMPANY_FAILED";

// BOOKMARK COMPANY
export const BOOKMARK_COMPANY = "BOOKMARK_COMPANY";
export const BOOKMARK_COMPANY_SUCCEEDED = "BOOKMARK_COMPANY_SUCCEEDED";
export const BOOKMARK_COMPANY_FAILED = "BOOKMARK_COMPANY_FAILED";

// UNBOOKMARK COMPANY
export const UNBOOKMARK_COMPANY = "UNBOOKMARK_COMPANY";
export const UNBOOKMARK_COMPANY_SUCCEED = "UNBOOKMARK_COMPANY_SUCCEED";
export const UNBOOKMARK_COMPANY_FAILED = "UNBOOKMARK_COMPANY_FAILED";

//GET COMPANY JOBS
export const GET_COMPANY_JOBS = "GET_COMPANY_JOBS";
export const GET_COMPANY_JOB_SUCCEED = "GET_COMPANY_JOB_SUCCEED";
export const GET_COMPANY_JOB_FAILED = "GET_COMPANY_JOB_FAILED";

//GET INDUSTRY
export const FETCHING_INDUSTRY = "FETCHING_INDUSTRY";
export const FETCHING_INDUSTRY_SUCCEED = "FETCHING_INDUSTRY_SUCCEED";
export const FETCHING_INDUSTRY_FAILED = "FETCHING_INDUSTRY_FAILED";

//GET STATE REGION
export const FETCHING_STATE_REGION = "FETCHING_STATE_REGION";
export const FETCHING_STATE_REGION_SUCCEED = "FETCHING_STATE_REGION_SUCCEED";
export const FETCHING_STATE_REGION_FAILED = "FETCHING_STATE_REGION_FAILED";
export const UPDATE_STATE_REGION = "UPDATE_STATE_REGION";

//GET COMPANY SIZE
export const FETCHING_COMPANY_SIZE = "FETCHING_COMPANY_SIZE";
export const FETCHING_COMPANY_SIZE_SUCCEED = "FETCHING_COMPANY_SIZE_SUCCEED";
export const FETCHING_COMPANY_SIZE_FAILED = "FETCHING_COMPANY_SIZE_FAILED";

// FETCHING DISCOVER COMPANIES
export const FETCHING_DISCOVER_COMPANIES = "FETCHING_DISCOVER_COMPANIES";
export const FETCHING_DISCOVER_COMPANIES_SUCCEED =
  "FETCHING_DISCOVER_COMPANIES_SUCCEED";
export const FETCHING_DISCOVER_COMPANIES_FAILED =
  "FETCHING_DISCOVER_COMPANIES_FAILED";

// FETCHING BOOKMARK COMPANY LIST
export const FETCH_COMPANY_BOOKMARK_LIST = "FETCH_COMPANY_BOOKMARK_LIST";
export const FETCH_COMPANY_BOOKMARK_LIST_SUCCEED =
  "FETCH_COMPANY_BOOKMARK_LIST_SUCCEED";
export const FETCH_COMPANY_BOOKMARK_LIST_FAILED =
  "FETCH_COMPANY_BOOKMARK_LIST_FAILED";
export const CLEAR_COMPANY_BOOKMARK_LIST = "CLEAR_COMPANY_BOOKMARK_LIST";

// FETCHING STATIC COMPANY LIST
export const FETCH_STATIC_COMPANIES_LIST = "FETCH_STATIC_COMPANIES_LIST";
export const FETCH_STATIC_COMPANIES_LIST_SUCCEED =
  "FETCH_STATIC_COMPANIES_LIST_SUCCEED";
export const FETCH_STATIC_COMPANIES_LIST_FAILED =
  "FETCH_STATIC_COMPANIES_LIST_FAILED";

// FETCHING HOMEPAGE HIRING COMPANIES
export const FETCHING_HOMEPAGE_HIRING_COMPANIES =
  "FETCHING_HOMEPAGE_HIRING_COMPANIES";
export const FETCH_HOMEPAGE_HIRING_COMPANIES_SUCCEED =
  "FETCH_HOMEPAGE_HIRING_COMPANIES_SUCCEED";
export const FETCH_HOMEPAGE_HIRING_COMPANIES_FAILED =
  "FETCH_HOMEPAGE_HIRING_COMPANIES_FAILED";
