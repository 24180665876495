import * as types from "../types/snackbar_type";

const initialState = {
  //For snackbar
  showSnackBar: false,
  snackBarMessage: "",
  key: "1",
  severity: "success",
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    //For snackbar
    case types.SHOW_SNACKBAR: {
      return {
        ...state,
        showSnackBar: true,
        key: action.key,
        snackBarMessage: action.snackBarMessage,
        severity: action.severity,
      };
    }
    case types.HIDE_SNACKBAR: {
      return {
        ...state,
        showSnackBar: false,
        snackBarMessage: "",
        key: "1",
      };
    }
    default: {
      return state;
    }
  }
};
