import * as types from "../types/snackbar_type";

const showSnackBar =
  (params = {}) =>
  async (dispatch) => {
    return dispatch({
      type: types.SHOW_SNACKBAR,
      snackBarMessage: params.message,
      severity: params.severity,
      key: new Date().getTime(),
    });
  };

const hideSnackBar = () => async (dispatch) => {
  return new Promise((resolve) => {
    return resolve(
      dispatch({
        type: types.HIDE_SNACKBAR,
      })
    );
  });
};

export { showSnackBar, hideSnackBar };
