import * as types from "../types/company_type";

const initialState = {
  // For company list
  fetchingCompanyList: false,
  companies: [],
  hasNextPage: false,
  companyCurrentPage: 1,
  companyListPageInfo: {},
  companyFilter: {
    industryIds: [],
    industryIdsFull: [],
  },
  clearingAll: false,
  companyReachedEnd: false,
  companyError: false,
  companyErrorMessage: "",
  isLoadingCompanies: false,
  currentLoadingCompanyKey: "",
  // For company static keyword search
  staticKeyword: "",
  // For company suggestion on mobile filter
  companySuggestions: [],
  suggestionPage: 1,
  fetchingCompanySuggestion: false,
  currentSuggestionKey: "",
  companySuggestionEnd: false,
  // For discover more company
  fetchingDiscoverMoreCompanyList: false,
  discoverMoreCompanies: [],
  discoverMoreCompanyPageInfo: {},
  discoverMoreCompanyCurrentPage: 1,
  discoverMoreCompanyReachedEnd: false,
  discoverMoreCompanyError: false,
  discoverMoreCompanyErrorMessage: "",
  // For fetching bookmarked job list
  bookmarkedCompanies: [],
  fetchingBookmarkCompanyList: false,
  bookmarkCompanyListPageInfo: {},
  fetchingBookmarkCompanyListError: false,
  // For fetching industry
  fetchingIndustry: false,
  fetchingIndustryError: false,
  industries: [],
  // For fetching state region
  fetchingStateRegion: false,
  fetchingStateRegionError: false,
  stateRegions: [],
  // For fetching company size
  fetchingCompanySize: false,
  fetchingCompanySizeError: false,
  companySizes: [],
  // For company profile
  selectedCompany: {},
  fetchingCompany: false,
  fetchingCompanyError: false,
  fetchingCompanyErrorMessage: "",
  // For bookmark company
  bookmarkingCompany: false,
  bookmarkingCompanyError: false,
  // For unbookmark company
  unbookmarkCompany: false,
  unbookmarkCompanyError: false,
  // For company job
  companyJobs: [],
  fetchingCompanyJob: false,
  fetchingCompanyJobError: false,
  // For cp discover more companies
  cpDiscoverMoreCompanies: [],
  fetchingCPDiscoverMoreCompany: false,
  fetchingCPDiscoverMoreCompanyError: false,
  // For discover companies
  discoverCompanies: [],
  fetchingDiscoverCompanies: false,
  // For static companies
  staticCompanies: [],
  fetchingStaticCompanies: false,
  // For homepage hiring companies
  homepageHiringCompanies: [],
  fetchingHomepageHiringCompanies: false,
  companyEndCursor: "",
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    // For company list
    case types.FETCHING_COMPANIES: {
      return {
        ...state,
        fetchingCompanyList: true,
      };
    }

    case types.CLEAR_END_CURSOR: {
      return {
        ...state,
        companyEndCursor: null,
      };
    }

    case types.FETCH_COMPANIES_SUCCEED: {
      const companyList = action.payload.companyList;
      let cCompanyList = state.companies;
      let companyEnd = action.payload.companyReachedEnd;
      let companyEndCursor = action.payload.companyEndCursor;

      let total = [];

      if (action.payload.fromFilter) {
        total = companyList;
      } else {
        total = [...cCompanyList, ...companyList];
      }

      return {
        ...state,
        companies: total,
        hasNextPage: action.payload.hasNextPage,
        companyReachedEnd: companyEnd,
        companyEndCursor: companyEndCursor,
        fetchingCompanyList: false,
        clearingAll: false,
      };
    }
    case types.FETCH_COMPANIES_FAILED: {
      let cFetchingCompanyList =
        action?.payload?.currentLoadingCompanyKey ==
        state.currentLoadingCompanyKey
          ? false
          : state.fetchingCompanyList;

      return {
        ...state,
        fetchingCompanyList: cFetchingCompanyList,
        companyError: true,
      };
    }
    case types.CLEAR_COMPANY_LIST: {
      return {
        ...state,
        companies: [],
        companyCurrentPage: 1,
        companyListPageInfo: {},
        companyReachedEnd: false,
      };
    }
    case types.END_OF_COMPANY_LIST: {
      return {
        ...state,
        companyReachedEnd: true,
        fetchingCompanyList: false,
        companyError: false,
        pageInfo: action.payload.pageInfo,
      };
    }
    case types.NO_COMPANIES_FOUND: {
      return {
        ...state,
        fetchingCompanyList: false,
        companyError: false,
      };
    }
    // For company suggestion
    case types.FETCH_COMPANY_SUGGESTION: {
      return {
        ...state,
        fetchingCompanySuggestion: true,
      };
    }
    case types.FETCH_COMPANY_SUGGESTION_SUCCEED: {
      let companySuggestionList = action.payload.refresh
        ? []
        : [...state.companySuggestions];
      let cPage = state.suggestionPage;
      let cFetchingCompanySuggestion = state.fetchingCompanySuggestion;
      let companySuggestionEnd = state.companySuggestionEnd;

      // Only update suggestion company list if currentSuggestionKey is the same as current state value
      if (action.payload.currentSuggestionKey == state.currentSuggestionKey) {
        let cCompanySuggestions = action.payload.companySuggestions.map(
          (item) => item.company
        );
        companySuggestionList = [
          ...companySuggestionList,
          ...cCompanySuggestions,
        ];
        cPage = action.payload.suggestionPage + 1;
        cFetchingCompanySuggestion = false;
        companySuggestionEnd =
          action.payload.companySuggestions.length >= 21 ? false : true;
      }

      return {
        ...state,
        fetchingCompanySuggestion: cFetchingCompanySuggestion,
        companySuggestions: companySuggestionList,
        suggestionPage: cPage,
        companySuggestionEnd: companySuggestionEnd,
      };
    }
    case types.FETCH_COMPANY_SUGGESTION_FAILED: {
      // Use back existing fetchingCompanySuggestion status if key does not match
      let cFetchingCompanySuggestion =
        action.payload.currentSuggestionKey == state.currentSuggestionKey
          ? false
          : state.fetchingCompanySuggestion;

      return {
        ...state,
        fetchingCompanySuggestion: cFetchingCompanySuggestion,
      };
    }
    case types.CLEAR_COMPANY_SUGGESTION: {
      return {
        ...state,
        suggestionPage: 1,
        companySuggestions: [],
      };
    }
    // For company filter
    case types.UPDATE_COMPANY_FILTER: {
      return {
        ...state,
        companyFilter: action.companyFilter,
      };
    }
    // For discover more company
    case types.FETCHING_DISCOVER_MORE_COMPANIES: {
      return {
        ...state,
        fetchingDiscoverMoreCompanyList: true,
      };
    }
    case types.FETCH_DISCOVER_MORE_COMPANIES_SUCCEED: {
      const companyList = action.payload.companyList.map(
        (item) => item.company
      );

      let cCompanyList = [...state.discoverMoreCompanies, ...companyList];
      let currentPage = action.payload.currentPage + 1;

      return {
        ...state,
        discoverMoreCompanies: cCompanyList,
        discoverMoreCompanyCurrentPage: currentPage,
        fetchingDiscoverMoreCompanyList: false,
        discoverMoreCompanyError: false,
      };
    }
    case types.FETCH_DISCOVER_MORE_COMPANIES_FAILED: {
      return {
        ...state,
        fetchingDiscoverMoreCompanyList: false,
        discoverMoreCompanyError: true,
      };
    }
    case types.CLEAR_DISCOVER_MORE_COMPANY_LIST: {
      return {
        ...state,
        discoverMoreCompanies: [],
        discoverMoreCompanyCurrentPage: 1,
        discoverMoreCompanyPageInfo: {},
        discoverMoreCompanyReachedEnd: false,
      };
    }
    case types.NO_DISCOVER_MORE_COMPANIES_FOUND: {
      return {
        ...state,
        fetchingDiscoverMoreCompanyList: false,
        discoverMoreCompanyError: true,
      };
    }
    case types.END_OF_DISCOVER_MORE_COMPANY_LIST: {
      return {
        ...state,
        discoverMoreCompanyReachedEnd: true,
        fetchingDiscoverMoreCompanyList: false,
        discoverMoreCompanyError: false,
        discoverMoreCompanyPageInfo: action.payload.pageInfo,
      };
    }
    // For fetching industry
    case types.FETCHING_INDUSTRY: {
      return {
        ...state,
        fetchingIndustry: true,
        fetchingIndustryError: false,
      };
    }
    case types.FETCHING_INDUSTRY_SUCCEED: {
      let initialIndustries = [];
      return {
        ...state,
        industries: [...initialIndustries, ...action.industries],
        fetchingIndustry: false,
        fetchingIndustryError: false,
      };
    }
    case types.FETCHING_INDUSTRY_FAILED: {
      return {
        ...state,
        fetchingIndustry: false,
        fetchingIndustryError: true,
      };
    }
    // For state region
    case types.FETCHING_STATE_REGION: {
      return {
        ...state,
        fetchingStateRegion: true,
        fetchingStateRegionError: false,
      };
    }
    case types.FETCHING_STATE_REGION_SUCCEED: {
      let initialStateRegions = [];
      return {
        ...state,
        stateRegions: [...initialStateRegions, ...action.stateRegions],
        fetchingStateRegion: false,
        fetchingStateRegionError: false,
      };
    }
    case types.FETCHING_STATE_REGION_FAILED: {
      return {
        ...state,
        fetchingStateRegion: false,
        fetchingStateRegionError: true,
      };
    }
    case types.UPDATE_STATE_REGION: {
      let initialStateRegions = [];
      return {
        ...state,
        stateRegions: [...initialStateRegions, ...action.stateRegions],
      };
    }
    // For company sizes
    case types.FETCHING_COMPANY_SIZE: {
      return {
        ...state,
        fetchingCompanySize: true,
        fetchingCompanySizeError: false,
      };
    }
    case types.FETCHING_COMPANY_SIZE_SUCCEED: {
      let initialCompanySizes = [];
      return {
        ...state,
        companySizes: [...initialCompanySizes, ...action.companySizes],
        fetchingCompanySize: false,
        fetchingCompanySizeError: false,
      };
    }
    case types.FETCHING_COMPANY_SIZE_FAILED: {
      return {
        ...state,
        fetchingCompanySize: false,
        fetchingCompanySizeError: true,
      };
    }
    // For company profile
    case types.UPDATE_SELECTED_COMPANY: {
      return {
        ...state,
        selectedCompany: action.selectedCompany,
      };
    }
    case types.FETCHING_COMPANY: {
      return {
        ...state,
        fetchingCompany: true,
      };
    }
    case types.FETCHING_COMPANY_SUCCEED: {
      return {
        ...state,
        selectedCompany: action.payload.selectedCompany,
        fetchingCompany: false,
      };
    }
    case types.FETCHING_COMPANY_FAILED: {
      return {
        ...state,
        fetchingCompany: false,
        fetchingCompanyError: true,
      };
    }
    // For bookmarked company list
    case types.FETCH_COMPANY_BOOKMARK_LIST: {
      return {
        ...state,
        fetchingBookmarkCompanyList: true,
      };
    }
    case types.FETCH_COMPANY_BOOKMARK_LIST_SUCCEED: {
      const bookmarkList = action.payload.edges.map((item) => item.node);
      let cBookmarkCompanies = [...state.bookmarkedCompanies, ...bookmarkList];

      return {
        ...state,
        bookmarkedCompanies: cBookmarkCompanies,
        bookmarkCompanyListPageInfo: action.payload.pageInfo,
        fetchingBookmarkCompanyList: false,
        fetchingBookmarkCompanyListError: false,
      };
    }
    case types.FETCH_COMPANY_BOOKMARK_LIST_FAILED: {
      return {
        ...state,
        fetchingBookmarkCompanyList: false,
        fetchingBookmarkCompanyListError: true,
      };
    }
    case types.CLEAR_COMPANY_BOOKMARK_LIST: {
      return {
        ...state,
        bookmarkedCompanies: [],
      };
    }
    // For bookmark company
    case types.BOOKMARK_COMPANY: {
      return {
        ...state,
        bookmarkingCompany: true,
      };
    }
    case types.BOOKMARK_COMPANY_SUCCEEDED: {
      return {
        ...state,
        bookmarkingCompany: false,
        bookmarkingCompanyError: false,
      };
    }
    case types.BOOKMARK_COMPANY_FAILED: {
      return {
        ...state,
        bookmarkingCompany: false,
        bookmarkingCompanyError: true,
      };
    }
    // For unbookmark company
    case types.UNBOOKMARK_COMPANY: {
      return {
        ...state,
        unbookmarkCompany: true,
      };
    }
    case types.UNBOOKMARK_COMPANY_SUCCEED: {
      let companyId = action.companyId;
      let bookmarkCompanies = state.bookmarkedCompanies.filter((bookmark) => {
        if ((bookmark.company && bookmark.company.id) == companyId) {
          return false;
        }
        return true;
      });

      return {
        ...state,
        bookmarkedCompanies: bookmarkCompanies,
        unbookmarkCompany: false,
        unbookmarkCompanyError: false,
      };
    }
    case types.UNBOOKMARK_COMPANY_FAILED: {
      return {
        ...state,
        unbookmarkCompany: false,
        unbookmarkCompanyError: true,
      };
    }
    // For company jobs
    case types.GET_COMPANY_JOBS: {
      return {
        ...state,
        fetchingCompanyJob: true,
      };
    }
    case types.GET_COMPANY_JOB_SUCCEED: {
      return {
        ...state,
        companyJobs: action.payload.companyJobs,
        fetchingCompanyJob: false,
      };
    }
    case types.GET_COMPANY_JOB_FAILED: {
      return {
        ...state,
        fetchingCompanyJob: false,
        fetchingCompanyJobError: true,
      };
    }
    // For cp discover more comnpanies
    case types.FETCHING_CP_DISCOVER_MORE_COMPANIES: {
      return {
        ...state,
        fetchingCPDiscoverMoreCompany: true,
      };
    }
    case types.FETCH_CP_DISCOVER_MORE_COMPANIES_SUCCEED: {
      let companyList = action.payload.edges.map((item) => item.node.company);
      companyList = companyList.filter((company) => {
        return company.id != action.payload.companyId;
      });
      companyList = companyList.slice(0, 6);

      return {
        ...state,
        cpDiscoverMoreCompanies: companyList,
        fetchingCPDiscoverMoreCompany: false,
      };
    }
    case types.FETCH_CP_DISCOVER_MORE_COMPANIES_FAILED: {
      return {
        ...state,
        fetchingCPDiscoverMoreCompany: false,
        fetchingCPDiscoverMoreCompanyError: true,
      };
    }
    // For discover companies
    case types.FETCHING_DISCOVER_COMPANIES_SUCCEED: {
      return {
        ...state,
        discoverCompanies: action.payload,
        fetchingDiscoverCompanies: false,
      };
    }
    case types.FETCHING_DISCOVER_COMPANIES: {
      return {
        ...state,
        fetchingDiscoverCompanies: true,
      };
    }
    case types.FETCHING_DISCOVER_COMPANIES_FAILED: {
      return {
        ...state,
        fetchingDiscoverCompanies: false,
      };
    }
    // For static companies
    case types.FETCH_STATIC_COMPANIES_LIST: {
      return {
        ...state,
        fetchingStaticCompanies: true,
      };
    }
    case types.FETCH_STATIC_COMPANIES_LIST_SUCCEED: {
      return {
        ...state,
        staticCompanies: action.payload,
      };
    }
    case types.FETCH_STATIC_COMPANIES_LIST_FAILED: {
      return {
        ...state,
        fetchingStaticCompanies: false,
      };
    }
    // For homepage hiring companies
    case types.FETCHING_HOMEPAGE_HIRING_COMPANIES: {
      return {
        ...state,
        fetchingHomepageHiringCompanies: true,
      };
    }
    case types.FETCH_HOMEPAGE_HIRING_COMPANIES_SUCCEED: {
      return {
        ...state,
        homepageHiringCompanies: action.companies,
        fetchingHomepageHiringCompanies: false,
      };
    }
    case types.FETCH_HOMEPAGE_HIRING_COMPANIES_FAILED: {
      return {
        ...state,
        fetchingHomepageHiringCompanies: false,
      };
    }

    default: {
      return state;
    }
  }
};
