import { createTheme } from "@mui/material/styles";
import { MUITypography } from "./muiFonts";

const theme = createTheme({
  palette: {
    primary: {
      main: `#20C472`,
    },
  },

  typography: { ...MUITypography },

  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          color: "white",
          borderRadius: "6px",
          fontFamily: "Karla",
        },
      },
    },
  },

  zIndex: {
    tooltip: 1000,
  },

  breakpoints: {
    // get down() {
    //   return (key) => `@media (max-width:${this.values[key] - 0.5}px)`;
    // },
    // get up() {
    //   return (key) => `@media (min-width:${this.values[key]}px)`;
    // },
    // get between() {
    //   return (keyA, keyB) =>
    //     `@media (min-width:${this.values[keyA]}px) and (max-width:${
    //       this.values[keyB] - 0.5
    //     }px)`;
    // },
    values: {
      xs: 0, // default from MUI
      mobile: 360,
      mobileL: 480,
      sm: 600, // default from MUI
      tablet: 768,
      tabletS: 780,
      md: 960,
      tabletL: 1200,
      lg: 1280,
      lgXl: 1600,
      desktop: 1440,
      xl: 1920,
      desktopL: 2560,
    },
  },
  styleOverrides: {
    body: {
      scrollbarColor: "#6b6b6b #512acc",
      "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
        backgroundColor: "#512acc",
      },
      "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
        borderRadius: 8,
        backgroundColor: "#512acc",
        minHeight: 24,
        border: "3px solid #2b2b2b",
      },
      "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
        backgroundColor: "#512acc",
      },
      "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
        {
          backgroundColor: "#512acc",
        },
      "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#512acc",
      },
      "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
        backgroundColor: "#512acc",
      },
    },
  },
});

export default theme;
