// USER LOGGED IN INFO
export const USER_TOKEN = "USER_TOKEN";
export const USER_PROFILE = "USER_PROFILE";

// Login
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

// Sign up
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILED = "SIGNUP_FAILED";

// Forgot password
export const FORGOT_PASSWORD_RESET_SUCCESS = "FORGOT_PASSWORD_RESET_SUCCESS";
export const FORGOT_PASSWORD_RESET_FAILED = "FORGOT_PASSWORD_RESET_FAILED";

// Log out
export const LOGOUT = "LOGOUT";

// Download resume
export const GET_RESUME_SUCCESS = "GET_RESUME_SUCCESS";
export const GET_RESUME_FAILED = "GET_RESUME_FAILED";

// Upload Resume
export const UPLOAD_RESUME_SUCCESS = "UPLOAD_RESUME_SUCCESS";
export const UPLOAD_RESUME_FAILED = "UPLOAD_RESUME_FAILED";

// Get user info
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_FAILED = "GET_USER_INFO_FAILED";

// Save Education Info
export const SAVE_EDUCATION_SUCCESS = "SAVE_EDUCATION_SUCCESS";
export const SAVE_EDUCATION_FAILED = "SAVE_EDUCATION_FAILED";

// Save Work Experience Info
export const SAVE_WORK_EXPERIENCE_SUCCESS = "SAVE_WORK_EXPERIENCE_SUCCESS";
export const SAVE_WORK_EXPERIENCE_FAILED = "SAVE_WORK_EXPERIENCE_FAILED";

// Save Basic Info
export const SAVE_BASIC_INFO_SUCCESS = "SAVE_BASIC_INFO_SUCCESS";
export const SAVE_BASIC_INFO_FAILED = "SAVE_BASIC_INFO_FAILED";

// Save profile image
export const SAVE_PROFILE_IMAGE_SUCCESS = "SAVE_PROFILE_IMAGE_SUCCESS";
export const SAVE_PROFILE_IMAGE_FAILED = "SAVE_PROFILE_IMAGE_FAILED";

// Submit Contact Us
export const SUBMIT_CONTACT_US_SUCCESS = "SUBMIT_CONTACT_US_SUCCESS";
export const SUBMIT_CONTACT_US_FAILED = "SUBMIT_CONTACT_US_FAILED";

// Reset password
export const RESET_PASSWORD_SUCCEED = "RESET_PASSWORD_SUCCEED";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

// FOR PDPA STATUS
export const FETCH_PDPA_STATUS_SUCCESS = "FETCH_PDPA_STATUS_SUCCESS";
export const UPDATE_PDPA_STATUS_SUCCEED = "UPDATE_PDPA_STATUS_SUCCEED";
export const UPDATE_PDPA_STATUS_FAILED = "UPDATE_PDPA_STATUS_FAILED";

// Clevertap init
export const CLEVERTAP_INIT = "CLEVERTAP_INIT";

export const ADD_WORK_EXPERIENCE = "ADD_WORK_EXPERIENCE";
export const REMOVE_WORK_EXPERIENCE = "REMOVE_WORK_EXPERIENCE";
export const UPDATE_WORK_EXPERIENCE = "UPDATE_WORK_EXPERIENCE";

export const ADD_EDUCATION = "ADD_EDUCATION";
export const REMOVE_EDUCATION = "REMOVE_EDUCATION";
export const UPDATE_EDUCATION = "UPDATE_EDUCATION";

export const UPDATE_SKILLS = "UPDATE_SKILLS";
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";

export const UPDATE_USER_PROFILE_MODAL = "UPDATE_USER_PROFILE_MODAL";
export const UPDATE_USER_PROFILE_DIALOG = "UPDATE_USER_PROFILE_DIALOG";
export const UPDATE_LEAVE_MODAL = "UPDATE_LEAVE_MODAL";

export const GET_LANGUAGE_SUCCEED = "GET_LANGUAGE_SUCCEED";
export const SAVE_LANGUAGE_SUCCESS = "SAVE_LANGUAGE_SUCCESS";
export const SAVE_LANGUAGE_FAILED = "SAVE_LANGUAGE_FAILED";

export const GET_SKILLS_SUCCEED = "GET_SKILLS_SUCCEED";
export const SAVE_SKILLS_SUCCESS = "SAVE_SKILLS_SUCCESS";
export const SAVE_SKILLS_FAILED = "SAVE_SKILLS_FAILED";

export const GET_JOB_TITLES_SUCCEED = "GET_JOB_TITLES_SUCCEED";
export const GET_EDUCATION_INSTITUTION_SUCCEED =
  "GET_EDUCATION_INSTITUTION_SUCCEED";

// FOR ONBOARDING
export const UPDATE_ONBOARDING_SUCCESS = "UPDATE_ONBOARDING_SUCCESS";
export const UPDATE_ONBOARDING_FAILED = "UPDATE_ONBOARDING_FAILED";

export const UPDATE_IS_EDITED = "UPDATE_IS_EDITED";

// GET STATE REGION
export const GET_STATEREGION = "GET_STATEREGION";
export const GET_STATEREGION_SUCCEEDED = "GET_STATEREGION_SUCCEEDED";
export const GET_STATEREGION_FAILED = "GET_STATEREGION_FAILED";
